@import "../../../styles/base/abstracts";

.hbs-article-topper {
  @include grid-parent;
  @include site-max-width;
  position: relative;
  justify-content: center;

  &::before {
    @include absolute(x x 100% 50%);
    background-color: $c-bg;
    content: "";
    height: $nav-bg-height;
    transform: translateX(-50%);
    width: 100vw;
  }
}

.hbs-article-topper--knowledge-base {
  justify-content: flex-start !important;
}

.hbs-article-topper--no-caption {
  padding-bottom: $grid-padding-mobile * 2;
  @include mq($bp-full-grid) {
    padding-bottom: $grid-padding * 2;
  }
}

.hbs-article-topper__content,
.hbs-article-topper__art {
  @include grid-child;
}

// Content
.hbs-article-topper__content {
  @include grid-parent;
  @include topper-spacing;
  align-items: center;
  flex-direction: column;
  margin-bottom: $spacing-xl;
  text-align: center;

  @include mq($bp-desktop) {
    margin-bottom: $spacing-xxl;
  }

  .hbs-article-topper--big-art & {
    margin-bottom: $spacing-md;
    margin-left: 0;
    margin-right: 0;
  }
}

.hbs-article-topper--knowledge-base .hbs-article-topper__content {
  align-items: flex-start;
  text-align: left;
}

.hbs-article-topper__overline {
  margin-bottom: $spacing-sm;
  display: inline-block;

  @include mq($bp-desktop) {
    margin-bottom: $spacing-md;
  }
}

.hbs-article-topper__heading {
  @include h1;
  margin-bottom: $spacing-md;

  @include mq($bp-desktop) {
    .hbs-article-topper--big-art & {
      @include giant;
    }
  }
}

.hbs-article-topper__heading--long {
  @include h2;
  max-width: 16em;

  @include mq($bp-desktop) {
    .hbs-article-topper--big-art & {
      @include h1;
    }
  }
}

.hbs-article-topper__subheading {
  @include intro-text($visually-align: true);
  margin-bottom: $spacing-md;
  max-width: 40em;

  .hbs-article-topper--big-art & {
    margin-bottom: $spacing-xl;
  }
}

.hbs-article-topper__meta {
  @include baseline-text;
  color: $c-text-light;
  display: block;
}

.hbs-article-topper__meta-item {
  @include baseline-text;
}

.hbs-article-topper__cta {
  margin-top: $spacing-sm;
}

// Art
.hbs-article-topper__art {
  .hbs-article-topper--big-art & {
    margin: 0;
    width: 100%;
  }
  .hbs-article-topper__art-caption {
    padding-bottom: $spacing-xs;
  }
}
